import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import { Collapse, Company } from '@hapvida/hapvida-core-components';

import { DependentProps } from '@services/BeneficiaryService';

import {
  BeneficiaryListSortNameEnum,
  BeneficiaryListColumns,
} from '@flows/private/beneficiaries/pages/BeneficiaryList/constants/columns';
import { BeneficiaryDetailRouter } from '@flows/private/beneficiaries/pages/BeneficiaryDetails/router';
import { useFilterParams } from '@hooks';
import {
  BeneficiaryType,
  Detail,
  DetailContract,
  Person,
  Plan,
} from '@flows/private/beneficiaries/components';

import { DependentComponent } from '../../@types';
import { type BeneficiaryListCardProps } from './types';

export function BeneficiaryListCard({
  beneficiaryList,
}: Readonly<BeneficiaryListCardProps>) {
  const navigate = useNavigate();
  const { handleTableOrderChange, handlePageChange, currentPage, tableOrder } =
    useFilterParams();

  const handleEdit = useCallback(
    (id: string) => {
      navigate(
        BeneficiaryDetailRouter.dynamicPath({ beneficiaryId: id } as any),
      );
    },
    [navigate],
  );

  const tableComponentsWithNDI = (healthOperatorType: string) => {
    if (healthOperatorType === 'NDI') {
      return 'NDI';
    }
    return healthOperatorType;
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };

  const beneficiaryRow = useCallback(
    (beneficiary: DependentProps) => ({
      detail: <Detail data={beneficiary} />,
      person: <Person data={beneficiary?.user} />,
      [BeneficiaryListSortNameEnum.COMPANY]: (
        <Company
          company={tableComponentsWithNDI(beneficiary?.healthOperator)}
        />
      ),
      [BeneficiaryListSortNameEnum.PLAN]: (
        <Plan
          name={beneficiary?.contractDetail?.planHealth?.name}
          code={beneficiary?.contractDetail?.planHealth?.code}
          ansCode={beneficiary?.contractDetail?.planHealth?.ansCode}
        />
      ),
      [BeneficiaryListSortNameEnum.PARTNER_COMPANY]: (
        <DetailContract data={beneficiary?.contractDetail} />
      ),
    }),
    [],
  );

  const tableComponents = beneficiaryList?.beneficiaries
    .map(item => {
      let mainRow = {};
      const dependents: DependentComponent[] = [];

      if (item?.holder) {
        mainRow = {
          ...beneficiaryRow(item?.holder),
          [BeneficiaryListSortNameEnum.TYPE]: <BeneficiaryType data={item} />,
        };

        if (item?.holder?.dependents && item?.holder?.dependents?.length > 0) {
          item?.holder?.dependents?.forEach((dependent: DependentProps) => {
            let dependentsRow = {};

            dependentsRow = {
              ...beneficiaryRow(dependent),
              [BeneficiaryListSortNameEnum.TYPE]: (
                <BeneficiaryType dependent={dependent} />
              ),
            };

            const rowDataDependents = {
              id: dependent?.code,
              itHasError: !!dependent?.review,
              errorMessage: dependent?.review?.message ?? '',
              components: dependentsRow,
            };

            dependents.push(rowDataDependents);
          });
        }

        const rowDataHolder = {
          id: item?.holder?.code,
          itHasError: !!item?.holder?.review,
          errorMessage: item?.holder?.review?.message ?? '',
          main: mainRow,
          dependents,
        };

        return rowDataHolder;
      }

      if (item?.beneficiary) {
        mainRow = {
          ...beneficiaryRow(item?.beneficiary),
          [BeneficiaryListSortNameEnum.TYPE]: <BeneficiaryType data={item} />,
        };

        const rowDataBeneficiary = {
          id: item?.beneficiary?.code,
          itHasError: !!item?.beneficiary?.review,
          errorMessage: item?.beneficiary?.review?.message ?? '',
          main: mainRow,
          dependents,
        };

        return rowDataBeneficiary;
      }

      return [];
    })
    .flat();

  return (
    <Collapse
      count={
        beneficiaryList ? Math.ceil(beneficiaryList.total / 10) : undefined
      }
      from={
        beneficiaryList
          ? 10 * (currentPage - 1) + beneficiaryList.beneficiaries.length
          : undefined
      }
      TableHeadColumns={BeneficiaryListColumns}
      onOrderChange={handleTableOrderChange}
      baseTableProps={{
        tableContainerHeight: '100%',
      }}
      onPageChange={handleChangePage}
      to={beneficiaryList?.total}
      currentPage={currentPage}
      tableOrder={tableOrder}
      data={tableComponents}
      onEdit={handleEdit}
      hasCellAction
    />
  );
}
