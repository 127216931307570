enum ExportBeneficiariesStatusEnum {
  PROCESSED = 'Processed',
  PROCESSING = 'Processing',
  PROCESS = 'Process',
}

interface ExportBeneficiariesResponseDTO {
  content: {
    status: ExportBeneficiariesStatusEnum;
  };
}

interface GetExportBeneficiariesResponseDTO {
  content: {
    status: ExportBeneficiariesStatusEnum;
    downloadLink?: string;
  };
}

export {
  GetExportBeneficiariesResponseDTO,
  ExportBeneficiariesStatusEnum,
  ExportBeneficiariesResponseDTO,
};
