import Stack from '@mui/material/Stack';

import { Icon } from '@hapvida/hapvida-core-components';

import { BeneficiaryDetailStatus } from '@services/BeneficiaryService';

import { Label } from './styles';
import type { DetailProps } from './types';

export const Detail: React.FC<DetailProps> = ({ data }: DetailProps) => {
  const iconStatusData = BeneficiaryDetailStatus[data.status];

  return (
    <Stack>
      <Stack direction="row" alignItems="center">
        <Icon
          size={20}
          name={iconStatusData?.iconName}
          color={iconStatusData?.color}
        />
        <Label fontSize="12px" fontWeight={700}>
          {iconStatusData?.label?.toUpperCase()}
        </Label>
      </Stack>
      <Label fontSize="14px" fontWeight={700}>
        {data?.code}
      </Label>
    </Stack>
  );
};
