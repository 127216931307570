import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';

import BeneficiaryService from '@services/BeneficiaryService';

import { toastifyApiErrors } from '@utils';
import { useFilterParams } from '@hooks';
import { PARAMS_ID } from '@flows/private/beneficiaries/pages/BeneficiaryDetails/constants';

export function useBeneficiaryDetailsState() {
  const { enqueueSnackbar } = useSnackbar();
  const { beneficiaryId = '' } = useParams();
  const { tableOrder } = useFilterParams({
    paramsId: PARAMS_ID.dependents,
  });

  const beneficiaryService = useMemo(() => new BeneficiaryService(), []);
  // TO-DO: Funcionalidade ocultada dado a funcionalidade de reviews ainda não estar pronta para v3
  // const { selectedCompany, selectedPartnerCompany } = useAuth();
  // const companyId = selectedCompany?.id ?? '';
  // const partnerCompany = selectedPartnerCompany?.partnerCompany ?? '';
  // // const isEnabled =
  // //   Boolean(beneficiaryId) && Boolean(companyId) && Boolean(partnerCompany);

  const beneficiaryDetailsState = useQuery({
    queryKey: ['beneficiaryDetailsState', beneficiaryId],
    queryFn: () => beneficiaryService.fetchBeneficiaryById(beneficiaryId),
    enabled: Boolean(beneficiaryId),
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  // TO-DO: Funcionalidade ocultada dado a funcionalidade de reviews ainda não estar pronta para v3
  // const beneficiaryReviewsState = useQuery({
  //   queryKey: [
  //     'beneficiaryReviewsState',
  //     beneficiaryId,
  //     companyId,
  //     partnerCompany,
  //   ],
  //   queryFn: () =>
  //     beneficiaryService.fetchBeneficiaryReviews(beneficiaryId, companyId),
  //   enabled: isEnabled,
  // });

  const beneficiaryDependentsState = useQuery({
    queryKey: ['beneficiaryDependentsState', beneficiaryId, tableOrder],
    queryFn: () =>
      beneficiaryService.fetchBeneficiaryDependents({
        beneficiaryId,
        filters: { ...tableOrder },
      }),
    enabled: Boolean(beneficiaryId),
  });

  const refreshBeneficiaryDetailsStates = () => {
    beneficiaryDetailsState.remove();
    beneficiaryDependentsState.remove();

    beneficiaryDetailsState.refetch();
    beneficiaryDependentsState.refetch();

    // TO-DO: Funcionalidade ocultada dado a funcionalidade de reviews ainda não estar pronta para v3
    // beneficiaryReviewsState.remove();
    // beneficiaryReviewsState.refetch();
  };

  return {
    beneficiaryDetailsState,
    // TO-DO: Funcionalidade ocultada dado a funcionalidade de reviews ainda não estar pronta para v3
    // beneficiaryReviewsState,
    beneficiaryDependentsState,
    refreshBeneficiaryDetailsStates,
  };
}
