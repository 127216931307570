import { Mask, Pill } from '@hapvida/hapvida-core-components';

import { type BeneficiaryDetailsProps } from '@services/BeneficiaryService/dtos/BeneficiaryDetailDTO';

import {
  CompanyTypeEnumColor,
  CompanyTypeEnumName,
  CompanyTypeEnum,
} from '@constants';

export const getBeneficiaryCompanyRows = (
  beneficiary?: BeneficiaryDetailsProps,
) => {
  if (!beneficiary) return [];

  return [
    {
      label: 'CNPJ',
      value: Mask.cnpj(beneficiary?.contractDetail?.unitDocument),
      xs: 3,
    },
    {
      label: 'Razão Social',
      value: beneficiary?.contractDetail?.companyName?.legal ?? '-',
      xs: 3,
    },
    {
      label: 'Nome fantasia',
      value: beneficiary?.contractDetail?.unitName?.trade ?? '-',
      xs: 3,
    },
    {
      label: 'Tipo',
      value:
        (
          <Pill
            fitContent
            text={
              CompanyTypeEnumName[
                beneficiary?.contractDetail?.companyType as CompanyTypeEnum
              ]
            }
            color={
              CompanyTypeEnumColor[
                beneficiary?.contractDetail?.companyType as CompanyTypeEnum
              ]
            }
          />
        ) ?? '-',
      xs: 3,
    },
    {
      label: 'Endereço',
      value: beneficiary?.contractDetail?.companyAddress?.street ?? '-',
      xs: 6,
    },
    {
      label: 'UF',
      value: beneficiary?.contractDetail?.companyAddress?.stateCode ?? '-',
      xs: 3,
    },
    {
      label: 'Cidade',
      value: beneficiary?.contractDetail?.companyAddress?.city ?? '-',
      xs: 3,
    },
  ];
};
