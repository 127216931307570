/* eslint-disable no-restricted-syntax */
import { HapvidaApi } from '@http';
import { FEATURE_TOGGLE } from '@constants';

import HapvidaMockApi from '../../http/HapvidaMock';
import type { BeneficiaryDetailsResponseDTO } from './dtos/BeneficiaryDetailDTO';
import type {
  BeneficiaryMovementRequestDTO,
  BeneficiaryMovementsResponseDTO,
} from './dtos/BeneficiaryMovementDTO';
import type {
  GetBeneficiaryRequestDTO,
  BeneficiariesResponseDTO,
  GetSummaryRequestDTO,
  BeneficiariesSummaryResponseDTO,
  BeneficiariesSummaryProps,
  BeneficiariesProps,
} from './dtos/ListBeneficiariesDTO';
import type {
  BeneficiaryDependentsRequestDTO,
  BeneficiaryDependentsResponseDTO,
} from './dtos/BeneficiaryDependentAggregateDTO';
import type { BeneficiaryReviewsResponseDTO } from './dtos/BeneficiaryReviewsDTO';
import { generateUrlParams } from '../../../utils/generateUrlParams';
import type { SuspendedBeneficiaryResponseDTO } from './dtos/SuspendedBeneficiaryDTO';
import {
  GetExportBeneficiariesResponseDTO,
  ExportBeneficiariesResponseDTO,
} from './dtos';

export * from './dtos';

const endpointVersion = FEATURE_TOGGLE.BENEFICIARIES_V3 ? 'v3' : 'v1';

export default class BeneficiaryService {
  private readonly endpoints = {
    list: '/beneficiaries',
    detail: '/beneficiary',
    movements: '/movements',
    stayLetter: (search: string) =>
      `api/${endpointVersion}/continuity-letter/beneficiary/${search}`,
  };

  public async fetchStayLetterFile(search: string) {
    const { data } = await HapvidaApi.get(this.endpoints.stayLetter(search));
    return data;
  }

  public async fetchBeneficiaries({
    companyId,
    ...filters
  }: GetBeneficiaryRequestDTO): Promise<BeneficiariesProps> {
    const params = generateUrlParams(filters);

    const { data } = await HapvidaApi.get<BeneficiariesResponseDTO>(
      `/api/${endpointVersion}/companies/${companyId}/beneficiaries`,
      {
        params,
      },
    );

    return data.content;
  }

  public async fetchBeneficiariesSummary({
    companyId,
    ...filters
  }: GetSummaryRequestDTO): Promise<BeneficiariesSummaryProps> {
    const params = generateUrlParams(filters);

    const { data } = await HapvidaApi.get<BeneficiariesSummaryResponseDTO>(
      `/api/v3/companies/${companyId}/beneficiaries/summary`,
      {
        params,
      },
    );

    return data.content;
  }

  public async fetchBeneficiaryById(id: string) {
    const { data } = await HapvidaApi.get<BeneficiaryDetailsResponseDTO>(
      `/api/v3/beneficiaries/${id}`,
    );

    return data.content;
  }

  public async fetchMovementsById(id: string) {
    const { data } = await HapvidaMockApi.get(`${this.endpoints.movements}`, {
      params: { id },
    });

    return data;
  }

  public async fetchMovementsTableById({
    beneficiaryId,
    companyId,
    limit,
    offset,
    filters,
  }: BeneficiaryMovementRequestDTO) {
    const { data } = await HapvidaApi.get<BeneficiaryMovementsResponseDTO>(
      `/api/v1/beneficiaries/${beneficiaryId}/${companyId}/movements`,
      {
        params: {
          limit,
          offset,
          ...filters,
        },
      },
    );

    return data.content;
  }

  public async fetchBeneficiaryDependents({
    beneficiaryId,
    filters,
  }: BeneficiaryDependentsRequestDTO) {
    const { data } = await HapvidaApi.get<BeneficiaryDependentsResponseDTO>(
      `/api/v3/beneficiaries/${beneficiaryId}/dependents`,
      {
        params: filters,
      },
    );

    return data.content.beneficiaries;
  }

  public async fetchBeneficiaryReviews(
    beneficiaryId: string,
    organizationalGroupId: string,
  ) {
    const { data } = await HapvidaApi.get<BeneficiaryReviewsResponseDTO>(
      `/api/v1/beneficiaries/${beneficiaryId}/companies/${organizationalGroupId}/reviews`,
    );

    return data.content;
  }

  public async exportBeneficiaries() {
    const { data } = await HapvidaApi.get<ExportBeneficiariesResponseDTO>(
      `/api/v3/beneficiaries/report`,
    );

    return data.content;
  }

  public async getExportBeneficiaries() {
    const { data } = await HapvidaApi.get<GetExportBeneficiariesResponseDTO>(
      `/api/v3/beneficiaries/status-report`,
    );

    return data.content;
  }

  public async getSuspendedBeneficiary() {
    const { data } = await HapvidaApi.get<SuspendedBeneficiaryResponseDTO>(
      `/api/v1/companies/has-suspended-beneficiary`,
    );

    return data.content;
  }
}
