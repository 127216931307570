import { FC } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { Container, Title, Label, Value } from './styles';
import { Loading } from '../Loading';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import type { DataGridProps } from './types';

const DataGrid: FC<DataGridProps> = ({
  title,
  rows,
  customSpacing = 2,
  containerStyles,
  gridContainerProps,
  isLoading,
  error,
}) => {
  return (
    <Container direction="column" sx={containerStyles}>
      {title && <Title>{title}</Title>}
      {isLoading && (
        <Loading
          paddingX={8}
          paddingY={3}
          message={
            <>
              Estamos carregando as informações. <br /> Em breve, tudo estará
              pronto para você
            </>
          }
        />
      )}
      {error?.hasError && !isLoading && (
        <ErrorLoadingPage onReloadClick={error?.onReloadClick} />
      )}
      {!isLoading && !error?.hasError && (
        <Grid container spacing={customSpacing} {...gridContainerProps}>
          {rows.map((item, index) => (
            <Grid
              key={`row-${String(index)}`}
              item
              xs={item.xs}
              md={item.md}
              sm={item.sm}
            >
              <Stack direction="column" spacing={1}>
                <Label>{item.label}</Label>

                {typeof item.value === 'string' ||
                typeof item.value === 'number' ? (
                  <Value>{item.value}</Value>
                ) : (
                  item.value ?? '-'
                )}
              </Stack>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default DataGrid;
