import { BeneficiaryTypeEnum } from '@constants';

const initialSummaryValues: Record<
  BeneficiaryTypeEnum | 'totalBeneficiaries',
  number
> = {
  [BeneficiaryTypeEnum.HOLDER]: 0,
  [BeneficiaryTypeEnum.DEPENDENT]: 0,
  [BeneficiaryTypeEnum.RESPONSIBLE]: 0,
  totalBeneficiaries: 0,
} as const;

export { initialSummaryValues };
