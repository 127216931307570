import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { Company, Mask, Pill } from '@hapvida/hapvida-core-components';

import type { DataGridItem } from '@hapvida/hapvida-core-components/src/components/DataGrid/types';

import { BillType } from '@services/FinancialService/dtos/BillsListDTO';
import type { BillDetails } from '@services/FinancialService';

import { getPillColor } from '@flows/private/financial/helpers/getPillColor';

export function useBillData(bill: BillDetails) {
  const convertBillMonthToCase = (billMonth?: string) => {
    if (billMonth) {
      return (
        billMonth.charAt(0).toUpperCase() + billMonth.toLowerCase().slice(1)
      );
    }
    return '-';
  };

  const commonData = useMemo(
    (): DataGridItem[] => [
      {
        value: <Typography variant="subtitle1">Detalhes da fatura</Typography>,
        label: '',
        xs: 12,
      },
      {
        value: <Typography variant="h5">{bill?.number}</Typography>,
        label: 'Nº da fatura',
        xs: 12,
      },
      {
        label: 'Status',
        xs: 3,
        value: (
          <Pill
            color={getPillColor(bill?.status)}
            text={bill?.status}
            fitContent
          />
        ),
      },
      {
        label: 'Operadora',
        xs: 3,
        value: <Company company={bill?.healthOperator} />,
      },
      {
        label: 'Nº Contrato/Cód. Empresa',
        xs: 3,
        value: <Typography variant="body2">{bill?.partnerCompany}</Typography>,
      },
      {
        label: 'Tipo de fatura',
        xs: 3,
        value: (
          <Typography variant="body2">{BillType[bill?.billType]}</Typography>
        ),
      },
      {
        label: 'Mês',
        xs: 3,
        value: (
          <Typography variant="body2" fontWeight={700}>
            {convertBillMonthToCase(bill?.month)}
          </Typography>
        ),
      },
      {
        label: 'Data da emissão',
        xs: 3,
        value: (
          <Typography variant="body2">{Mask.date(bill?.period)}</Typography>
        ),
      },
      {
        label: 'Data de vencimento',
        xs: 3,
        value: (
          <Typography variant="body2" fontWeight={700}>
            {Mask.date(bill?.dueDate)}
          </Typography>
        ),
      },
      {
        label: 'Valor',
        xs: 3,
        value: (
          <Typography variant="body2" fontWeight={700}>
            {Mask.currency(bill?.amount)}
          </Typography>
        ),
      },
    ],
    [bill],
  );

  return {
    commonData,
  };
}
