import Stack from '@mui/material/Stack';

import { Mask } from '@hapvida/hapvida-core-components';

import { Label } from './styles';
import { Genders, PersonProps } from './types';

export function Person({ data }: Readonly<PersonProps>) {
  return (
    <Stack>
      <Label fontSize="12px" fontWeight={400}>
        {Genders[data?.gender?.toUpperCase()] ?? '-'}
      </Label>
      <Label fontSize="14px" fontWeight={700}>
        {data?.name}
      </Label>
      {data?.document?.length === 11 ? (
        <Label fontSize="12px" fontWeight={400}>
          {Mask.cpf(data?.document, true) ?? '-'}
        </Label>
      ) : (
        <Label fontSize="12px" fontWeight={400}>
          {Mask.cpfNDITenDigits(data?.document, true) ?? '-'}
        </Label>
      )}
    </Stack>
  );
}
