import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import CompanyService from '@services/CompanyService';

import { toastifyApiErrors } from '@utils';
import { useAuth } from '@hooks';
import { FEATURE_TOGGLE } from '@constants';

export function useCompanyDetailsState() {
  const { id = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany, selectedPartnerCompany } = useAuth();

  const companyId = selectedCompany?.id ?? '';
  const partnerCompany = selectedPartnerCompany?.partnerCompany ?? '';

  const companyService = useMemo(() => new CompanyService(), []);

  const companyDetailsState = useQuery({
    queryKey: ['companyDetailsState', id],
    queryFn: () =>
      companyService.fetchCompanyDetail({
        id,
      }),
    enabled: Boolean(id),
    onError: (error: unknown) => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const isEnabled =
    Boolean(companyId) &&
    Boolean(id) &&
    companyDetailsState?.isSuccess &&
    Boolean(partnerCompany);

  const companyDetailsSummaryState = useQuery({
    queryKey: ['companyDetailsSummaryState', companyId, id, partnerCompany],
    queryFn: () =>
      companyService.fetchCompanyBeneficiaryNumbers({
        companyId,
        afiliateId: FEATURE_TOGGLE.BENEFICIARIES_V3
          ? companyDetailsState?.data?.company?.unitCode.toString()
          : id,
      }),
    enabled: isEnabled,
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshCompanyDetailsStates = () => {
    companyDetailsState.remove();
    companyDetailsSummaryState.remove();

    companyDetailsState.refetch();
    companyDetailsSummaryState.refetch();
  };

  return {
    companyDetailsState,
    companyDetailsSummaryState,
    refreshCompanyDetailsStates,
  };
}
