import type {
  CalculateSummaryValuesBeneficiariesProps,
  CalculateSummaryValuesInitialValuesProps,
  CalculateSummaryValuesResultProps,
} from './types';

export function calculateSummaryValues(
  beneficiaries: CalculateSummaryValuesBeneficiariesProps[],
  initialValues: CalculateSummaryValuesInitialValuesProps,
): CalculateSummaryValuesResultProps {
  return beneficiaries.reduce(
    (acc, beneficiary) => {
      acc[beneficiary?.beneficiaryType] =
        (acc[beneficiary?.beneficiaryType] ?? 0) +
        (beneficiary?.totalBeneficiaries ?? 0);
      return acc;
    },
    { ...initialValues, totalBeneficiaries: 0 },
  );
}
