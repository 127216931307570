import { Stack, Typography } from '@mui/material';

import {
  ErrorLoadingPage,
  FilterSummary,
  Loading,
  NoSearchResults,
} from '@hapvida/hapvida-core-components';

import { useFilterParams } from '@hooks';
import { EmptyResult } from '@components';

import { useStayLetter } from './hooks/useStayLetter';
import { LetterOfStayListCard } from './components';

export function LetterOfStayLayout() {
  const { searchParam } = useFilterParams();
  const {
    errorState,
    loadingState,
    beneficiaryState,
    stayLetterFile,
    refreshBeneficiary,
  } = useStayLetter();

  const thereIsBeneficiary = beneficiaryState?.code;
  const searchValue = searchParam.search;

  if (!searchValue) {
    return (
      <EmptyResult message="Para visualizar as cartas de permanência, busque pelo beneficiário no campo de busca." />
    );
  }

  if (errorState) {
    return <ErrorLoadingPage onReloadClick={refreshBeneficiary} />;
  }

  if (loadingState) {
    return (
      <Loading
        padding={8}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  const filterSummaryLabel = `${beneficiaryState ? 1 : 0}   ${
    beneficiaryState ? 'beneficiário' : 'beneficiários'
  }`;

  return (
    <Stack paddingTop={3} gap={3} paddingBottom={8}>
      <FilterSummary totalLabel={filterSummaryLabel} />

      {!thereIsBeneficiary && searchParam.search && (
        <NoSearchResults padding={5} />
      )}

      {thereIsBeneficiary && beneficiaryState && (
        <>
          <Typography variant="subtitle1">Carta de permanência</Typography>
          <LetterOfStayListCard
            beneficiary={beneficiaryState}
            stayLetterFile={stayLetterFile}
          />
        </>
      )}
    </Stack>
  );
}
