import { Grid } from '@mui/material';

import { useDrawerFilter, DateFilter } from '@hapvida/hapvida-core-components';

import {
  OrganizationalGroupDrawerFilter,
  BeneficiaryTypeDrawerFilter,
  SearchField,
  FiltersDrawerButton,
  SubHeader,
} from '@components';
import { useFilterParams } from '@hooks';

import { useBeneficiariesState } from '../../hooks';
import { BeneficiariesExportButton } from './components';

export function BeneficiariesSubHeader() {
  const { beneficiariesState, beneficiariesSummaryState } =
    useBeneficiariesState();
  const { filterCount } = useDrawerFilter();
  const { hasFilters } = useFilterParams();

  const totalBeneficiariesLength = beneficiariesState?.data?.total ?? 0;

  const isResultEmpty = totalBeneficiariesLength === 0;

  const isLoading =
    beneficiariesState?.isLoading || beneficiariesSummaryState?.isLoading;
  const hasError =
    beneficiariesState?.isError || beneficiariesSummaryState?.isError;
  const isEmpty = isResultEmpty && !hasFilters;

  if ((isLoading && !hasFilters) || hasError || isEmpty) return null;

  const filterDisabled =
    (!filterCount && beneficiariesState?.isLoading) ||
    (!filterCount && isResultEmpty);

  return (
    <SubHeader>
      <Grid container columnSpacing={3}>
        <Grid item md={8}>
          <SearchField
            currentResultLength={totalBeneficiariesLength}
            placeholder="Buscar cód. beneficiário, nome, CPF"
          />
        </Grid>
        <Grid item md={2}>
          <FiltersDrawerButton
            subtitle="Selecione como deseja filtrar seus beneficiários"
            title="Filtrar beneficiários"
            disabled={filterDisabled}
          >
            <BeneficiaryTypeDrawerFilter />

            <OrganizationalGroupDrawerFilter />

            <DateFilter />

            {/* REMINDER: Filtros desabilitados por solicitação da área de negócios, porém podem retornar quando o sistema for atuar como multi operadora
             <HealthOperatorDrawerFilter />
             <BeneficiaryStatusDrawerFilter /> */}
          </FiltersDrawerButton>
        </Grid>
        <Grid item md={2}>
          <BeneficiariesExportButton />
        </Grid>
      </Grid>
    </SubHeader>
  );
}
