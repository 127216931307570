import { DataGrid } from '@hapvida/hapvida-core-components';

import { useBeneficiaryDetailsState } from '@flows/private/beneficiaries/pages/BeneficiaryDetails/hooks';

import type { BeneficiaryDataGridCardProps } from './types';

export function BeneficiaryDataGridCard({
  formatRows,
  title,
}: Readonly<BeneficiaryDataGridCardProps>) {
  const { beneficiaryDetailsState, refreshBeneficiaryDetailsStates } =
    useBeneficiaryDetailsState();

  const { isLoading, isError } = beneficiaryDetailsState;

  const beneficiary = beneficiaryDetailsState.data;

  const rows = formatRows(beneficiary);

  return (
    <DataGrid
      error={{
        hasError: isError,
        onReloadClick: refreshBeneficiaryDetailsStates,
      }}
      title={title}
      isLoading={isLoading}
      rows={rows}
    />
  );
}
