export const FEATURE_TOGGLE = {
  OPERATOR_CLINIPAM: false,
  COMPANY_SETUP: false,
  ADD_USER: true,
  COMPANY_LIST: false,
  CANCEL_MOVEMENT: false,
  EDIT_AVATAR: false,
  NEW_PASSWORD_RECOVERY_LAYOUT: false,
  CUT_OFF_DATE: false,
  BACKOFFICE_DISABLED_ROUTES: false,
  BENEFICIARIES_V3: true,
};
