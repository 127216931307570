import Stack from '@mui/material/Stack';

import { BeneficiaryTypeEnum } from '@constants';

import { BeneficiaryDependentsAggregatedCard } from './components/BeneficiaryDependentsAggregatedCard';
import { BeneficiaryDataGridCard } from './components/BeneficiaryDataGridCard';
import { getBeneficiaryCompanyRows } from './utils/getBeneficiaryCompanyRows';
import { getBeneficiarySummaryRows } from './utils/getBeneficiarySummaryRows';
import { useBeneficiaryDetailsState } from './hooks';

export function BeneficiaryDetailsLayout() {
  const { beneficiaryDetailsState } = useBeneficiaryDetailsState();
  const { type } = beneficiaryDetailsState.data ?? {};
  const showDependentsCard = type !== BeneficiaryTypeEnum.DEPENDENT;

  return (
    <Stack spacing={3} paddingTop={3} paddingBottom={10}>
      <BeneficiaryDataGridCard
        title="Resumo"
        formatRows={getBeneficiarySummaryRows}
      />

      <BeneficiaryDataGridCard
        title="Empresa"
        formatRows={getBeneficiaryCompanyRows}
      />

      {showDependentsCard && <BeneficiaryDependentsAggregatedCard />}

      {/* REMINDER: Componentes desabilitados por solicitação da área de negócios, porém podem retornar ou serem ajustados após entendimento dos dados que serão exibidos no fluxo
      {beneficiaryReviews && <BeneficiaryReviewsCard />}

      <BeneficiaryMovementsCard />

      <BeneficiaryRegistrationDataCard />

      <BeneficiaryContactsCard />

      <BeneficiaryDocumentsCard />

      <BeneficiaryProfessionalInformationCard />

      <BeneficiaryInactiveCard />

      <BeneficiaryBankDataCard />

      <BeneficiaryAddressCard />

      <BeneficiaryAdditionalInformationCard />

      <BeneficiaryComplementaryInformationCard /> */}
    </Stack>
  );
}
