import { COLORS } from '@hapvida/hapvida-core-components';

enum BeneficiaryMovementTypeEnum {
  HOLDER_INCLUSION = 'HolderInclusion',
  DEPENDENT_INCLUSION = 'DependentInclusion',
  EXCLUSION = 'Exclusion',
  CADASTRAL_CHANGE = 'CadastralChange',
  CONTRACT_TRANSFER = 'ContractTransfer',
  EXCLUSION_CANCELATION = 'ExclusionCancelation',
  REACTIVATION = 'Reactivation',
  PLAN_CHANGE = 'PlanChange',
  RETIRED = 'Retired',
}

enum MovementBeneficiaryTypeEnum {
  HOLDER = 'Holder',
  RESPONSIBLE = 'Responsible',
  DEPENDENT = 'Dependent',
  AGGREGATE = 'Aggregate',
}

const MOVEMENT_BENEFICIARY_TYPE_NAME: Record<
  MovementBeneficiaryTypeEnum,
  string
> = {
  [MovementBeneficiaryTypeEnum.RESPONSIBLE]: 'Responsável sem direito',
  [MovementBeneficiaryTypeEnum.DEPENDENT]: 'Dependente',
  [MovementBeneficiaryTypeEnum.HOLDER]: 'Titular',
  [MovementBeneficiaryTypeEnum.AGGREGATE]: 'Agregado',
};

const MOVEMENT_BENEFICIARY_TYPE_COLOR: Record<
  MovementBeneficiaryTypeEnum,
  string
> = {
  [MovementBeneficiaryTypeEnum.DEPENDENT]: COLORS.PRIMARY.ORANGE['+1'],
  [MovementBeneficiaryTypeEnum.RESPONSIBLE]: COLORS.PRIMARY.BLUE.MAIN,
  [MovementBeneficiaryTypeEnum.HOLDER]: COLORS.PRIMARY.BLUE.MAIN,
  [MovementBeneficiaryTypeEnum.AGGREGATE]: COLORS.SECONDARY.BLUE.MAIN,
};

export {
  MOVEMENT_BENEFICIARY_TYPE_NAME,
  MOVEMENT_BENEFICIARY_TYPE_COLOR,
  BeneficiaryMovementTypeEnum,
  MovementBeneficiaryTypeEnum,
};
