import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Collapse, Loading } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import { useFilterParams } from '@hooks';
import { BeneficiaryDetailRouter } from '@flows/private/beneficiaries/pages/BeneficiaryDetails/router';
import { useBeneficiaryDetailsState } from '@flows/private/beneficiaries/pages/BeneficiaryDetails/hooks';
import { PARAMS_ID } from '@flows/private/beneficiaries/pages/BeneficiaryDetails/constants';
import { EmptyResult } from '@components';

import { TableContainer, TableTitle } from './styles';
import { dependentsAggregatedListTableHeadColumns } from './constants';
import { getBeneficiaryDependentsAggregatedListTableRows } from './utils';

export function BeneficiaryDependentsAggregatedCard() {
  const { beneficiaryDependentsState } = useBeneficiaryDetailsState();

  const { data: beneficiaryDependents = [], isLoading } =
    beneficiaryDependentsState;

  const { handleTableOrderChange, tableOrder } = useFilterParams({
    paramsId: PARAMS_ID.dependents,
  });

  const navigate = useNavigate();

  const handleEdit = useCallback(
    (id: string) => {
      navigate(
        BeneficiaryDetailRouter.dynamicPath({ beneficiaryId: id } as any),
      );
    },
    [navigate],
  );

  const isEmpty = beneficiaryDependents?.length === 0;

  const beneficiaryDependentsAggregatedListTableRows =
    getBeneficiaryDependentsAggregatedListTableRows(beneficiaryDependents);

  return (
    <TableContainer>
      <TableTitle>Dependentes</TableTitle>
      {isLoading && (
        <Loading
          padding={8}
          message={
            <>
              Estamos carregando as informações. <br /> Em breve, tudo estará
              pronto para você
            </>
          }
        />
      )}
      {isEmpty && !isLoading && (
        <EmptyResult message="Esse beneficiário ainda não tem nenhum dependente cadastrado." />
      )}
      {!isLoading && !isEmpty && (
        <Collapse
          showFooter={false}
          data={beneficiaryDependentsAggregatedListTableRows}
          TableHeadColumns={dependentsAggregatedListTableHeadColumns}
          onEdit={handleEdit}
          onOrderChange={handleTableOrderChange}
          tableOrder={tableOrder}
          rowColor={COLORS.MONOCHROMATIC.GRAY1}
        />
      )}
    </TableContainer>
  );
}
