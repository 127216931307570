import { Button } from '@mui/material';
import { useEffect, useMemo } from 'react';

import { COLORS, Icon } from '@hapvida/hapvida-core-components';

import { CircularProgress } from '@hapvida/hapvida-core-components/src/components/CircularProgress';

import { ExportBeneficiariesStatusEnum } from '@services/BeneficiaryService';

import { useExportBeneficiariesFile } from './useExportBeneficiariesFile';
import type { ButtonInfoMemoProps } from './types';

export function BeneficiariesExportButton() {
  const {
    isExportingBeneficiaries,
    handleExportBeneficiariesFile,
    isReadyToDownload,
    handleDownloadBeneficiariesFile,
    refetch,
    status,
    isFetching,
  } = useExportBeneficiariesFile();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (status === ExportBeneficiariesStatusEnum.PROCESSING) {
        refetch();
      }
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [status, isFetching]);

  const exportButtonText = (() => {
    if (isExportingBeneficiaries) {
      return 'Exportando';
    }

    if (isReadyToDownload) {
      return 'Baixar beneficiários';
    }

    return 'Exportar beneficiários';
  })();

  const buttonInfoMemo: ButtonInfoMemoProps = useMemo(() => {
    if (isReadyToDownload) {
      return {
        buttonVariant: 'contained',
        buttonColor: 'success',
        iconName: 'download',
        onClick: handleDownloadBeneficiariesFile,
      };
    }
    return {
      buttonVariant: 'outlined',
      buttonColor: 'primary',
      iconName: 'clipboard-pulse',
      onClick: handleExportBeneficiariesFile,
    };
  }, [
    isReadyToDownload,
    handleDownloadBeneficiariesFile,
    handleExportBeneficiariesFile,
  ]);

  const { buttonColor, buttonVariant, iconName, onClick } = buttonInfoMemo;

  return (
    <Button
      onClick={onClick}
      disabled={isExportingBeneficiaries}
      variant={buttonVariant}
      color={buttonColor}
      fullWidth
      endIcon={
        isExportingBeneficiaries ? (
          <CircularProgress
            progressColor={COLORS?.MONOCHROMATIC?.GRAY4}
            size={16}
          />
        ) : (
          <Icon name={iconName} size={20} />
        )
      }
    >
      {exportButtonText}
    </Button>
  );
}
