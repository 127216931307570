import { Stack, Typography } from '@mui/material';

import { Company, Icon, Pill } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import {
  BeneficiaryDetailStatus,
  type BeneficiaryDetailsProps,
} from '@services/BeneficiaryService/dtos/BeneficiaryDetailDTO';

import { beneficiaryDocument } from '@flows/private/beneficiaries/pages/BeneficiaryDetails/utils/formatDocumentBeneficiary';
import handleGender from 'utils/handleGender';
import {
  BENEFICIARY_TYPE_NAME,
  BENEFICIARY_TYPE_COLOR,
  HealthOperatorEnum,
} from '@constants';
import { Plan } from '@flows/private/beneficiaries/components';
import type { PlanProps } from '@flows/private/beneficiaries/components/Plan/types';

import { beneficiaryDetailCivilStatusEnum } from '../constants/civilStatus';

const onPlan = (plan: PlanProps) => <Plan {...plan} />;

const onName = (name: string) => (
  <Typography
    sx={{
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '24px',
      color: COLORS.MONOCHROMATIC.GRAY4,
    }}
  >
    {name}
  </Typography>
);

export const getBeneficiarySummaryRows = (
  beneficiary?: BeneficiaryDetailsProps,
) => {
  if (!beneficiary) return [];

  const plan = onPlan({
    code: beneficiary?.contractDetail?.planHealth?.code,
    name: beneficiary?.contractDetail?.planHealth?.name,
    ansCode: beneficiary?.contractDetail?.planHealth?.ansCode,
  });
  const name = onName(beneficiary?.name?.full);

  const healthOperator =
    beneficiary?.healthOperator === HealthOperatorEnum.HAPVIDA ||
    beneficiary?.healthOperator === HealthOperatorEnum.CCG
      ? beneficiary?.contractDetail?.partnerCompany
      : beneficiary?.contractDetail?.number;

  const thisBeneficiaryStatus =
    BeneficiaryDetailStatus[beneficiary?.statusDetail?.description];

  return [
    { label: 'Nome completo', value: name, xs: 6 },
    {
      label: 'Status',
      value: (
        <Stack flexDirection="row" alignItems="center">
          <Icon
            name={thisBeneficiaryStatus?.iconName}
            color={thisBeneficiaryStatus?.color}
            size={20}
          />
          <Typography fontSize="12px">
            <b>{thisBeneficiaryStatus?.label.toUpperCase() ?? '-'}</b>
          </Typography>
        </Stack>
      ),
      xs: 3,
    },
    {
      label: 'Operadora',
      value: <Company company={beneficiary?.healthOperator} />,
      xs: 3,
    },
    {
      label: 'Tipo',
      value:
        (
          <Pill
            fitContent
            color={BENEFICIARY_TYPE_COLOR[beneficiary?.type]}
            text={BENEFICIARY_TYPE_NAME[beneficiary?.type]}
          />
        ) ?? '-',
      xs: 3,
    },
    { label: 'Cód. Beneficiário', value: beneficiary?.code, xs: 3 },
    {
      label: 'Número do contrato / Código da empresa conveniada',
      value: healthOperator,
      xs: 3,
    },
    { label: 'Plano', value: plan, xs: 3 },
    {
      label: 'Unidade',
      value: beneficiary?.contractDetail?.unitCode ?? '-',
      xs: 3,
    },
    {
      label: 'CPF',
      value: beneficiaryDocument(beneficiary?.document?.personalDocument),
      xs: 3,
    },
    { label: 'Sexo', value: handleGender(beneficiary?.gender?.type), xs: 3 },
    {
      label: 'Estado Civil',
      value: beneficiaryDetailCivilStatusEnum[beneficiary?.civilStatus] ?? '-',
      xs: 3,
    },
    {
      label: 'Data de inclusão do beneficiário',
      value: beneficiary?.startAt ?? '-',
      xs: 3,
    },
    {
      label: 'Data de nascimento',
      value: beneficiary?.birthDate,
      xs: 3,
    },
  ];
};
