import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

enum BeneficiaryTypeEnum {
  HOLDER = 'Titular',
  RESPONSIBLE = 'Responsavel sem direito',
  DEPENDENT = 'Dependente',
}

const BENEFICIARY_TYPE_NAME: Record<BeneficiaryTypeEnum, string> = {
  [BeneficiaryTypeEnum.RESPONSIBLE]: 'Responsável sem direito',
  [BeneficiaryTypeEnum.DEPENDENT]: 'Dependente',
  [BeneficiaryTypeEnum.HOLDER]: 'Titular',
};

const BENEFICIARY_TYPE_PLURAL_NAME: Record<BeneficiaryTypeEnum, string> = {
  [BeneficiaryTypeEnum.RESPONSIBLE]: 'Responsáveis sem direito',
  [BeneficiaryTypeEnum.DEPENDENT]: 'Dependentes',
  [BeneficiaryTypeEnum.HOLDER]: 'Titulares',
};

const BENEFICIARY_TYPE_COLOR: Record<BeneficiaryTypeEnum, string> = {
  [BeneficiaryTypeEnum.DEPENDENT]: COLORS.PRIMARY.ORANGE['+1'],
  [BeneficiaryTypeEnum.RESPONSIBLE]: COLORS.PRIMARY.BLUE.MAIN,
  [BeneficiaryTypeEnum.HOLDER]: COLORS.PRIMARY.BLUE.MAIN,
};

export {
  BeneficiaryTypeEnum,
  BENEFICIARY_TYPE_NAME,
  BENEFICIARY_TYPE_PLURAL_NAME,
  BENEFICIARY_TYPE_COLOR,
};
